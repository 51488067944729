import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Strong, Em, List, Span, Image, Icon, LinkBox, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { FaRegWindowClose, FaRegPlusSquare, FaTrashRestore } from "react-icons/fa";
import { IoIosArrowUp } from "react-icons/io";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Wavedropper LTD - Empowering Your Digital Presence with Precision
			</title>
			<meta name={"description"} content={"We craft bespoke web solutions that flow naturally with your business growth."} />
			<meta property={"og:title"} content={"Wavedropper LTD - Empowering Your Digital Presence with Precision"} />
			<meta property={"og:description"} content={"We craft bespoke web solutions that flow naturally with your business growth."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/anim-optimized.gif?v=2024-07-04T11:36:37.486Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.TopNavBar overflow-y="hidden" overflow-x="hidden">
			<Override slot="text" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="image" />
			<Override slot="SectionContent" />
			<Override slot="linkBox" />
			<Override slot="quarklycommunityKitMenu" />
			<Override slot="link" />
			<Override slot="box3" />
			<Override slot="image1" />
			<Override slot="quarklycommunityKitMobileSidePanelOverride5" />
		</Components.TopNavBar>
		<Section
			padding="50px 0 50px 0"
			quarkly-title="Hero-25"
			position="relative"
			min-height="100vh"
			background="linear-gradient(0deg,rgba(255, 255, 255, 0.9) 0%,rgba(247, 251, 255, 0.9) 100%),url(https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/overlaygoldenwaves.svg?v=2024-07-04T13:08:46.053Z) center center/cover repeat scroll padding-box"
			overflow-x="hidden"
			overflow-y="hidden"
		>
			<Override
				slot="SectionContent"
				lg-justify-content="center"
				max-width="1220px"
				flex-direction="row"
				flex-wrap="wrap"
				margin="80px auto 0px auto"
				sm-margin="10px auto 0px auto"
				overflow-x="visible"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="6fr 3fr"
				sm-grid-template-columns="1fr"
				position="relative"
			>
				<Box
					lg-margin="0px 0px 40px 0px"
					display="flex"
					lg-width="100%"
					color="--primaryBlack"
					sm-margin="0px 0px 0 0px"
				>
					<Box
						lg-width="100%"
						sm-margin="40px 0px 40px 0px"
						sm-padding="0px 0px 0px 0px"
						display="flex"
						lg-margin="0px 0px 40px 0px"
						md-align-items="flex-start"
						flex-direction="column"
						lg-align-items="flex-start"
					>
						<Text
							margin="0px 0px 16px 0px"
							font="--base"
							letter-spacing="1px"
							text-transform="uppercase"
							quarkly-title="Brand name"
							sm-display="none"
						>
							wavedropper
						</Text>
						<Text
							margin="0px 0px 36px 0px"
							font="--headline1"
							sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
							md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
							md-text-align="left"
							quarkly-title="Main title"
						>
							Elevate Your Digital Presence with Precision
						</Text>
						<Text
							lg-width="80%"
							width="70%"
							sm-width="100%"
							margin="0px 0px 0px 0px"
							font="--lead"
							md-text-align="left"
							quarkly-title="Subtitle"
						>
							Crafting bespoke web solutions that flow naturally with your business growth.
						</Text>
					</Box>
				</Box>
				<Box
					lg-margin="0px 0px 40px 0px"
					display="flex"
					lg-width="100%"
					justify-content="center"
					align-items="center"
				>
					<Components.WaverAnimation
						animationDuration="5"
						animationScale="0.95"
						animationTranslateY="5%"
						cursor="grab"
						fillColor="#2a9d8f"
						strokeColor="#1f2421"
						top={0}
						left={0}
						right={0}
						bottom={0}
					>
						<Components.AnimatedLogo fillAnimationSpeed="1s" strokeAnimationSpeed="8s" strokeColor="#1f2421" fillColor="#2a9d8f" />
					</Components.WaverAnimation>
				</Box>
			</Box>
			<Components.CtAbutton
				width="100%"
				display="grid"
				justify-items="center"
				align-items="center"
				padding="44px 0px 0px 0px"
				buttonTarget="contactform"
				scrollDuration="1000"
				delay="0"
				position="relative"
			>
				<Override
					slot="button"
					background="--color-primaryBlack"
					font="--lead"
					focus-box-shadow="none"
					border-radius="15px"
				>
					Get started
				</Override>
			</Components.CtAbutton>
		</Section>
		<Components.Services
			background="linear-gradient(0deg,rgba(255, 255, 255, 0.95) 0%,rgba(255, 255, 255, 0.9) 100%) scroll"
			justify-content="center"
			overflow-x="hidden"
			overflow-y="hidden"
			padding="88px 0px 88px 0px"
		>
			<Override slot="tabContent1" />
			<Override slot="image1" />
			<Override slot="tabContent2" />
			<Override slot="image2" />
			<Override slot="nav" justify-content="center" as="nav" />
			<Override slot="tabContent3" />
			<Override slot="tabContent" margin="30px 0px 0px 0px" opacity="1">
				<Override slot="sdli1">
					<Strong>
						Custom Websites:
					</Strong>
					{" "}Tailored designs created just for your brand.
				</Override>
				<Override
					slot="Title-tab1"
					font="italic 300 16px/1.5 --fontFamily-googlePoppins"
					color="--primaryBlack"
					margin="16px 0px 30px 0px"
					text-align="center"
				>
					Professional online presence tailored to your business – because even your website deserves to look its Sunday best.
				</Override>
				<Override
					slot="Subtitle1-tab1"
					font="--lead"
					text-align="center"
					margin="16px 0px 30px 0px"
					color="--primaryBlue"
				>
					Service Details
				</Override>
				<Override slot="sdli2">
					<Strong>
						Quick Turnaround:
					</Strong>
					{" "}Ready in just a few days.
				</Override>
				<Override slot="sdli3">
					<Strong>
						Eye-Catching Effects:
					</Strong>
					{" "}We add animations for a striking, dynamic look.
				</Override>
				<Override slot="sdli4">
					<Strong>
						Future Scalability:
					</Strong>
					{" "}Built with technology that makes it easy to expand as your business grows.
				</Override>
				<Override
					slot="Subtitle2-tab1"
					font="--lead"
					text-align="center"
					margin="16px 0px 30px 0px"
					color="--primaryBlue"
				>
					Pricing Options
				</Override>
				<Override slot="poli1">
					<Strong>
						One-Time Payment:
					</Strong>
					{" "}Starting at £600. Manage your own website with an easy visual editor. Hosting fees may apply, which you'll handle. If you need additional features, we can develop those for you.
				</Override>
				<Override slot="poli2">
					<Strong>
						Ongoing Management Service:
					</Strong>
					{" "}From £36/month, with a £50 discount on the initial development fee. We take care of updates and changes for you. Hosting fees are included.
				</Override>
				<Override
					slot="Subtitle3-tab1"
					font="--lead"
					text-align="center"
					margin="16px 0px 30px 0px"
					color="--primaryBlue"
				>
					Benefits
				</Override>
				<Override slot="be2li1">
					<Strong>
						Peace of Mind:
					</Strong>
					{" "}Focus on your business while we manage your website.
				</Override>
				<Override slot="be2li2">
					<Strong>
						Aligned Solutions:
					</Strong>
					{" "}Tailored to meet your specific business needs.
				</Override>
				<Override slot="be2li3">
					<Strong>
						Scalable:
					</Strong>
					{" "}Ready to grow alongside your business.
				</Override>
				<Override
					slot="tabContent"
					font="--base"
					color="--primaryBlack"
					padding="30px 50px 80px 50px"
					display="grid"
					sm-padding="30px 15px 30px 15px"
				/>
				<Override slot="Service details" list-style-type="none" md-padding="0px 0px 0px 0" padding="0px 0px 0px 0" />
				<Override
					slot="Title-tab2"
					as="h2"
					font="italic 300 16px/1.5 --fontFamily-googlePoppins"
					color="--primaryBlack"
					text-align="center"
				>
					Let us handle all the technical details so you can focus on your business – because nobody wants to fiddle with code when there's tea to be had!
				</Override>
				<Override
					slot="Subtitle1-tab2"
					font="normal 400 20px/1.5 --fontFamily-googlePoppins"
					text-align="center"
					margin="16px 0px 30px 0px"
					color="--primaryBlue"
				>
					Service Details
				</Override>
				<Override slot="sd2li1">
					<Strong>
						Full Management:
					</Strong>
					{" "}We handle everything from hosting to updates, ensuring your website runs as smoothly as a well-oiled cricket bat.
				</Override>
				<Override slot="sd2li2">
					<Strong>
						Business Alignment:
					</Strong>
					{" "}We assess your business requirements and ensure your website and integrated services align perfectly with your needs.
				</Override>
				<Override slot="sd2li3">
					<Strong>
						Third-Party Integrations:
					</Strong>
					{" "}We take care of setting up and integrating necessary tools like analytics and SEO services, with your approval.
				</Override>
				<Override slot="sd2li4">
					<Strong>
						Security and Performance:
					</Strong>
					{" "}We ensure your website is secure and performs brilliantly, meeting the latest standards.
				</Override>
				<Override
					slot="Subtitle2-tab2"
					font="--lead"
					text-align="center"
					margin="16px 0px 30px 0px"
					color="--primaryBlue"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
					Benefits & Costs
				</Override>
				<Override slot="Title-tab3" font="italic 300 16px/1.5 --fontFamily-googlePoppins" color="--primaryBlack" text-align="center">
					Give your website a boost with bespoke functionalities tailored to your needs – exclusively for the websites we create.
				</Override>
				<Override slot="sd3li1">
					<Strong>
						Custom Features:
					</Strong>
					{" "}We develop stylish and functional features tailored to your needs. This includes advanced forms, interactive elements, dynamic sections, and more.
				</Override>
				<Override
					slot="Subtitle1-tab3"
					font="--lead"
					text-align="center"
					margin="16px 0px 30px 0px"
					color="--primaryBlue"
				>
					Service Details
				</Override>
				<Override slot="sd3li2" font="--base">
					<Strong>
						Extended Functionality:
					</Strong>
					{" "}Enhance your website's capabilities with bespoke features designed to improve user experience and engagement.
				</Override>
				<Override slot="sd3li3" display="none" />
				<Override
					slot="Subtitle2-tab3"
					font="--lead"
					text-align="center"
					margin="16px 0px 30px 0px"
					color="--primaryBlue"
				>
					How It Works
				</Override>
				<Override slot="be3li1">
					<Strong>
						Request a Feature:
					</Strong>
					{" "}Let us know what custom feature you need.
				</Override>
				<Override slot="be3li2">
					<Strong>
						Development and Integration:
					</Strong>
					{" "}We develop and seamlessly integrate the feature into your website.
				</Override>
				<Override slot="sd2li5">
					<Strong>
						Transparent Communication:
					</Strong>
					{" "}We keep you in the loop about any changes and suggestions, promoting clear and open communication.
				</Override>
				<Override slot="sd3li4" display="none" />
				<Override slot="sd3li5" display="none" />
				<Override slot="be3li3">
					<Strong>
						Continuous Improvement:
					</Strong>
					{" "}As your needs grow, we can create additional custom features to further enhance your website's functionality.
				</Override>
				<Override
					slot="tab1-cwrapper"
					display="grid"
					grid-template-columns="repeat(3, 1fr)"
					sm-grid-template-columns="1fr"
					md-grid-template-columns="1fr"
					grid-gap="16px 50px"
				/>
				<Override slot="Pricing options" list-style-type="none" md-padding="0px 0px 0px 0" padding="0px 0px 0px 0" />
				<Override slot="Benefits" list-style-type="none" md-padding="0px 0px 0px 0" padding="0px 0px 0px 0" />
				<Override
					slot="tab2-cwrapper"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					sm-grid-template-columns="1fr"
					md-grid-template-columns="1fr"
					grid-gap="16px 50px"
				/>
				<Override
					slot="Service details-tab2"
					list-style-type="none"
					md-padding="0px 0px 0px 0"
					text-align="justify"
					padding="0px 0px 0px 0"
				/>
				<Override slot="Service details-tab3" list-style-type="none" md-padding="0px 0px 0px 0" padding="0px 0px 0px 0" />
				<Override slot="Benefits3" list-style-type="none" md-padding="0px 0px 0px 0" padding="0px 0px 0px 0" />
				<Override
					slot="t3-cwrapper"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					md-grid-template-columns="1fr"
					grid-gap="16px 50px"
				/>
				<Override slot="more-tab1" justify-self="center" margin="30px 0px 0 0px" display="none">
					<Override slot="button" background="--color-primaryBlack" border-radius="15px" focus-box-shadow="none">
						Learn more
					</Override>
				</Override>
				<Override slot="more-tab2" justify-self="center" margin="30px 0px 30px 0px" display="none">
					<Override slot="button" background="--color-primaryBlack" border-radius="15px" focus-box-shadow="none">
						Learn more
					</Override>
				</Override>
				<Override slot="more-tab3" justify-self="center" margin="30px 0px 30px 0px" display="none">
					<Override slot="button" background="--color-primaryBlack" border-radius="15px" focus-box-shadow="none">
						Learn more
					</Override>
				</Override>
				<Override slot="be3li4" font="italic 300 16px/1.5 --fontFamily-googlePoppins" display="none">
					Note: This service is exclusively for websites we have created.
				</Override>
				<Override slot="be3li5" display="none" />
				<Override slot="beli4">
					<Strong>
						Neat and Efficient:
					</Strong>
					{" "}Beautifully designed websites that perform exceptionally well.
				</Override>
				<Override slot="beli5" display="none" />
				<Override slot="be2li4">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Management Fee:
					</Strong>
					{" "}Starting from £30/month, with a £50 discount on the initial development fee.
				</Override>
				<Override slot="be2li5">
					<Strong>
						Hosting Fee:
					</Strong>
					{" "}All hosting-related fees are included in your monthly management fee.
				</Override>
				<Override slot="be2li6">
					<Strong>
						Changes:
					</Strong>
					{" "}Content updates, like changing text or images, are included. Design changes or those requiring development start from £56/task.
				</Override>
				<Override slot="beli1">
					<Strong>
						Professional Design:
					</Strong>
					{" "}Stylish, modern designs tailored to your business.
				</Override>
				<Override slot="beli2">
					<Strong>
						Responsive:
					</Strong>
					{" "}Perfectly optimised for desktops, tablets, and mobiles.
				</Override>
				<Override slot="beli3">
					<Strong>
						Scalable:
					</Strong>
					{" "}Ready to grow alongside your business.
				</Override>
			</Override>
			<Override slot="ul" />
			<Override
				slot="li"
				font="normal 600 20px/1.5 --fontFamily-googlePoppins"
				padding="8px 8px 8px 8px"
				text-align="center"
				sm-padding="8px 8px 8px 8px"
				sm-border-radius="15px"
				sm-hover-box-shadow="none"
				box-shadow="--m"
				border-radius="15px"
				margin="0px 6px 0px 6px"
				hover-box-shadow="none"
				transition="all 0.5s ease 0s"
				border-width="1px"
				border-color="rgba(58, 63, 167, 0.5)"
				border-style="solid"
			/>
			<Override
				slot="selectedLi"
				font="normal 600 20px/1.5 --fontFamily-googlePoppins"
				color="--primaryBlack"
				padding="8px 8px 8px 8px"
				text-align="center"
				sm-padding="8px 8px 8px 8px"
				margin="0px 6px 0px 6px"
				border-width="1px"
				border-style="solid"
				border-color="transparent"
			/>
			<Override slot="SectionContent" />
			<Override slot="SelectedUnderline" background="--color-primaryBlue" />
		</Components.Services>
		<Section
			padding="88px 0 88px 0"
			lg-padding="80px 0 90px 0"
			quarkly-title="Stages/Steps-5"
			sm-padding="30px 0 30px 0"
			sm-overflow-x="hidden"
			overflow-y="hidden"
			margin="0 auto 0 auto"
			overflow-x="hidden"
		>
			<Override slot="SectionContent" sm-min-width="0px" sm-margin="0px 0 0px 0" sm-width="100%" />
			<Text
				margin="0px 0px 80px 0px"
				font="--headline2"
				color="--darkL1"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				The Process
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
				flex-direction="column"
				align-self="center"
			>
				<Box min-height="1px" display="flex" flex-direction="row" lg-padding="0px 25px 0 0">
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						align-items="center"
						justify-content="center"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							1
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="0 0 0 1px"
						border-style="solid"
						border-color="#c8ced7"
						padding="0px 0px 0px 20px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						md-grid-template-columns="1fr"
						margin="0px 0px 0px -20px"
					>
						<Box
							min-width="100px"
							min-height="100px"
							color="--primaryBlack"
							padding="0px 0px 0px 25px"
							sm-padding="0px 0px 0px 0"
						>
							<Text border-color="--color-light" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans" text-align="left">
								Assessing Needs
							</Text>
							<Text
								font="--base"
								color="#65696d"
								text-align="justify"
								border-color="#7a7c7f"
								padding="0px 0px 50px 0px"
							>
								For a starter website, you’ll fill out a form detailing your requirements, target audience, desired user experience, and any existing ideas. Don’t worry if you get stuck – we’re here to help every step of the way, making sure the website looks and works just how you want it.
								<br />
								<br />
								For advanced custom features, simply provide a few written sentences describing your needs and goals. We’ll then schedule an online consultation to gain a deeper understanding. If your project requires it, we’ll assign a dedicated project manager to ensure smooth communication and coordination.
							</Text>
						</Box>
						<Components.ImageScrollAnimation imageUrl="https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/Assessing_Needs.webp?v=2024-06-24T10:11:13.499Z" margin="-40px 0px 0px 0px" md-order="-1">
							<Override slot="SectionContent" margin="0px 0 0px 0" />
							<Override slot="Image" width="300px" height="300px" />
						</Components.ImageScrollAnimation>
					</Box>
				</Box>
				<Box flex-direction="row" lg-padding="0px 25px 0 0" display="flex">
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						align-items="center"
						justify-content="center"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							2
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						border-width="0 0 0 1px"
						border-style="solid"
						border-color="#c8ced7"
						padding="0px 0px 0px 20px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						md-grid-template-columns="1fr"
					>
						<Box
							min-width="100px"
							min-height="100px"
							color="--primaryBlack"
							padding="0px 0px 0px 25px"
							sm-padding="0px 0px 0px 0"
						>
							<Text border-color="--color-light" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans" text-align="left">
								Design and Development
							</Text>
							<Text
								font="--base"
								color="#65696d"
								text-align="justify"
								border-color="#7a7c7f"
								padding="0px 0px 50px 0px"
							>
								Once we agree on the terms, we start designing and developing your website. Depending on the project, this phase might include creating flowcharts, mockups, and prototypes to ensure everything matches your vision and feedback. We also assist in planning the content structure to make the user experience smooth and engaging, helping to boost conversions using well-researched strategies and proven techniques.
							</Text>
						</Box>
						<Components.ImageScrollAnimation imageUrl="https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/Design_and_Development.webp?v=2024-06-24T10:11:13.531Z" margin="-40px 0px 0px 0px" md-order="-1">
							<Override slot="SectionContent" margin="0px 0 0px 0" />
							<Override slot="Image" width="300px" height="300px" />
						</Components.ImageScrollAnimation>
					</Box>
				</Box>
				<Box flex-direction="row" lg-padding="0px 25px 0 0" display="flex">
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						align-items="center"
						justify-content="center"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							3
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						border-width="0 0 0 1px"
						border-style="solid"
						border-color="#c8ced7"
						padding="0px 0px 0px 20px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						md-grid-template-columns="1fr"
					>
						<Box
							min-width="100px"
							min-height="100px"
							color="--primaryBlack"
							padding="0px 0px 0px 25px"
							sm-padding="0px 0px 0px 0"
						>
							<Text border-color="--color-light" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans" text-align="left">
								Testing and Auditing
							</Text>
							<Text
								font="--base"
								color="#65696d"
								text-align="justify"
								border-color="#7a7c7f"
								padding="0px 0px 50px 0px"
							>
								We thoroughly test and audit your website to spot and fix any issues. This includes checking usability, performance, and security to ensure everything works perfectly and meets our high standards.
							</Text>
						</Box>
						<Components.ImageScrollAnimation imageUrl="https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/Testing_and_Auditing.webp?v=2024-06-24T10:11:13.501Z" margin="-40px 0px 0px 0px" md-order="-1">
							<Override slot="SectionContent" margin="0px 0 0px 0" />
							<Override slot="Image" width="300px" height="300px" />
						</Components.ImageScrollAnimation>
					</Box>
				</Box>
				<Box flex-direction="row" lg-padding="0px 25px 0 0" display="flex">
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						align-items="center"
						justify-content="center"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							4
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						md-grid-template-columns="1fr"
					>
						<Box
							min-width="100px"
							min-height="100px"
							color="--primaryBlack"
							padding="0px 0px 0px 25px"
							sm-padding="0px 0px 0px 0"
						>
							<Text border-color="--color-light" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans" text-align="left">
								Launch & Support
							</Text>
							<Text
								font="--base"
								color="#65696d"
								text-align="justify"
								border-color="#7a7c7f"
								padding="0px 0px 50px 0px"
							>
								We launch your website or feature, ensuring everything goes live without a hitch. After the launch, we offer ongoing support based on our agreement. This includes regular updates, maintenance, and performance monitoring to make sure your site or app continues to meet your needs.
							</Text>
						</Box>
						<Components.ImageScrollAnimation imageUrl="https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/Launch_and_Support.webp?v=2024-06-24T10:11:13.498Z" margin="-40px 0px 0px 0px" padding="24px 0px 0 0px" md-order="-1">
							<Override slot="SectionContent" margin="0px 0 0px 0" />
							<Override slot="Image" width="300px" height="300px" />
						</Components.ImageScrollAnimation>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="88px 0 88px 0"
			quarkly-title="Solutions"
			sm-padding="40px 0 40px 0"
			overflow-y="hidden"
			overflow-x="hidden"
		>
			<Box
				min-width="100px"
				min-height="100px"
				align-items="center"
				display="flex"
				justify-content="center"
				padding="0 0px 80px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline2" text-align="center">
					Bundle Details & Cost Estimates
				</Text>
			</Box>
			<Box min-width="100px" min-height="100px" display="grid">
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					grid-template-columns="1fr 1fr"
					grid-gap="30px"
					flex-direction="column"
					margin="0px auto 0px auto"
					md-grid-template-columns="1fr"
				>
					<Components.SingleAccordion background="#ffffff" cursor="pointer">
						<Override slot="Acc Title" font="--headline3">
							Foundation Package
						</Override>
						<Override slot="Acc Description" font="--base">
							Perfect for small businesses and freelancers who need a professional online presence with the flexibility to grow in the future.
						</Override>
						<Override slot="Acc Price" font="--lead" text-align="center">
							from £600
						</Override>
						<Override slot="Acc Header" cursor="pointer" />
						<Override slot="AH Group Wrapper" min-height="250px" />
						<Override slot="AH Group 1" display="grid" />
						<Override slot="AH Ico" src="https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/Untitled-1.png?v=2024-06-27T18:26:25.942Z" />
						<Override slot="AH Group 2" height="150px" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/brickwall.svg?v=2024-07-01T12:14:06.978Z) 50% 50% /contain no-repeat scroll padding-box" />
						<Override slot="Acc Open Ico" />
						<Override slot="Icon Wrapper" />
						<Override slot="read more" />
						<Override slot="Acc Body" cursor="default" />
						<Components.AddOnList
							full_list="1 additional page creation: from £56 ,Custom contact form with price calculator: from £180 ,Retrieve content from Airtable (CSR): from £110 ,PayPal payment integration: from £150 one-off ,SEO content writing for 5 pages: from £300 ,Strapi CMS integration (SSG): coming soon..."
							base_price="600"
							flex-direction="column"
							position="relative"
							padding="0px 16px 0px 16px"
							border-radius="15px"
							display="grid"
							border-width="0 1px 1px 1px"
							border-style="solid"
							border-color="--color-lightD2"
							cursor="default"
						>
							<Override
								slot="Remove Icon"
								category="fa"
								icon={FaRegWindowClose}
								size="32px"
								color="--ctaRed"
								opacity="0.5"
							/>
							<Override slot="Reorder Item" />
							<Override slot="Total Price Label" right={0} font="--lead" text-align="center">
								Total Price: £600
							</Override>
							<Override slot="Price Disclaimer" font="--base" text-align="justify" padding="0px 16px 0px 16px">
								Feel free to add or remove the example add-ons by drag & drop or by clicking the +/- sign on the right.
								<br />
								<Em>
									The prices below are estimates and may vary based on your specific requirements.
								</Em>
							</Override>
							<Override slot="Price Disclaimer on Mobile" font="--base" text-align="justify" padding="0px 16px 0px 16px">
								Feel free to add or remove the example add-ons or by clicking the +/- sign on the right.
								<br />
								<Em>
									The prices below are estimates and may vary based on your specific requirements.
								</Em>
							</Override>
							<Override slot="Reorder 0 Item" />
							<Override slot="Delete Button" filter="sepia(50%)" />
							<Override slot="Reorder Group" display="grid" />
							<Override slot="RestorIcoWrapper" display="grid" align-self="flex-end" />
							<Override slot="restor ico label" margin="0 5px 0 0px" text-align="right">
								reset
							</Override>
							<Override slot="AddonsGrouping" display="flex" margin="0px 0px 16px 0px" />
							<Override slot="price ext" display="none" />
							<Override slot="ColumnTitle1" color="--primaryBlue" font="--base" opacity="0.8">
								Added Items
							</Override>
							<Override slot="ColumnTitle2" color="--primaryBlue" font="--base" opacity="0.8">
								Removed Items
							</Override>
							<Override
								slot="Column1Wrapper"
								display="grid"
								flex-direction="column"
								align-items="center"
								justify-content="center"
								align-content="center"
								justify-items="center"
								width="100%"
								background="rgba(42, 157, 143, 0.1)"
								border-radius="15px"
								margin="0px 0px 6px 0px"
								padding="0px 6px 0px 6px"
								cursor="default"
							/>
							<Override
								slot="Column2Wrapper"
								display="grid"
								flex-direction="column"
								align-items="center"
								justify-content="center"
								align-content="center"
								justify-items="center"
								width="100%"
								background="rgba(219, 43, 57, 0.1)"
								border-radius="15px"
								margin="6px 0px 16px 0px"
								padding="0px 6px 0px 6px"
								cursor="default"
							/>
							<Override
								slot="Add Icon"
								category="fa"
								icon={FaRegPlusSquare}
								size="32px"
								color="--accent"
							/>
							<Override slot="Add Button" opacity="0.5" />
							<Override slot="Price sub wrapper" font="--lead" />
							<Box min-width="100px" min-height="100px" padding="16px 0px 0 0px" grid-gap="16px">
								<List list-style-type="circle" as="ul" font="normal 300 16px/1.5 --fontFamily-googlePoppins">
									<Text>
										<Strong>
											React-Based Website:
										</Strong>
										{" "}Built on Gatsby for top-notch performance.
									</Text>
									<Text>
										<Strong>
											Up to 5 Pages:
										</Strong>
										{" "}Includes a homepage, 404 page, T&Cs, Privacy Policy, and one additional page.
									</Text>
									<Text>
										<Strong>
											Custom Design:
										</Strong>
										{" "}Features transitions and animations tailored to your brand.
									</Text>
									<Text>
										<Strong>
											Custom Contact Form:
										</Strong>
										{" "}100 emails/month included for free.
									</Text>
									<Text>
										<Strong
											overflow-wrap="normal"
											word-break="normal"
											white-space="normal"
											text-indent="0"
											text-overflow="clip"
											hyphens="manual"
											user-select="auto"
											pointer-events="auto"
										>
											Essentials:
										</Strong>
										<br />
										Google Tag Manager Setup
										<br />
										Google Analytics Setup
										<br />
										Google Search Console Setup
										<br />
										CloudFlare Integration
										<br />
										Cookie Consent Setup: GDPR compliant
									</Text>
									<Text>
										<Strong>
											Customer Support:
										</Strong>
										{" "}Email support available during UK office hours.
									</Text>
									<Text>
										<Strong>
											Ownership and Access:
										</Strong>
										{" "}You own the code and can modify it through the Quarkly visual editor (tutorial provided upon request).
									</Text>
									<Text>
										<Strong>
											Quick Turnaround:
										</Strong>
										{" "}Once plans are approved, the site can be built within 7 days.
									</Text>
								</List>
								<Text font="--lead" text-align="center" margin="32px 0px 0 0px">
									Example Add-ons Cost Calculator
								</Text>
							</Box>
						</Components.AddOnList>
					</Components.SingleAccordion>
					<Components.SingleAccordion background="#ffffff" cursor="pointer">
						<Override slot="Acc Title" font="--headline3">
							SiteCare Plan
						</Override>
						<Override slot="Acc Description" font="--base">
							For those who prefer us to take care of everything, including hosting, monitoring, and maintenance.
						</Override>
						<Override slot="Acc Price" font="--lead" text-align="center">
							from £36/month
						</Override>
						<Override slot="Acc Header" />
						<Override slot="AH Group Wrapper" display="flex" min-height="250px" />
						<Override slot="AH Group 1" width="70%" padding="16px 16px 0 16px" display="grid" />
						<Override slot="AH Ico" src="https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/Untitled-1.png?v=2024-06-27T18:26:25.942Z" />
						<Override
							slot="AH Group 2"
							width="30%"
							background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/puzzle.svg?v=2024-07-01T12:14:06.944Z) center/contain no-repeat scroll padding-box"
							margin="16px 16px 16px 16px"
							height="150px"
						/>
						<Override
							slot="Acc Open Ico"
							category="io"
							icon={IoIosArrowUp}
							transform="scaleX(2)"
							color="--ctaRed"
						/>
						<Override
							slot="Icon Wrapper"
							display="flex"
							justify-content="center"
							align-items="center"
							background="--color-lightD1"
							border-radius="0 0 15px 15px"
						/>
						<Override slot="read more" text-align="center" font="--base" margin="0 0px 0 0px">
							read more
						</Override>
						<Override slot="Acc Body" cursor="default" />
						<Components.AddOnList
							full_list="Few content changes to a page: This counts as 1 task for £56 ,Layout design changes on the nav bar component: 1 task for £56 ,Minor content changes on the entire website: 1 task for £56 "
							base_price="36"
							flex-direction="column"
							position="relative"
							padding="0px 16px 0px 16px"
							border-radius="15px"
							display="grid"
							border-width="0 1px 1px 1px"
							border-style="solid"
							border-color="--color-lightD2"
							cursor="default"
						>
							<Override
								slot="Restore Icon"
								category="fa"
								icon={FaTrashRestore}
								size="28px"
								color="--light"
							/>
							<Override
								slot="Remove Icon"
								category="fa"
								icon={FaRegWindowClose}
								size="32px"
								color="--ctaRed"
								opacity="0.5"
							/>
							<Override
								slot="Restore Button"
								color="--accent"
								display="grid"
								justify-items="center"
								align-items="center"
								border-radius="15px"
								cursor="pointer"
								background="--color-accent"
								z-index="0"
								justify-self="end"
								padding="10px 10px 10px 10px"
								margin="0px 0px 0 0px"
							/>
							<Override slot="Reorder Item" />
							<Override slot="Total Price Label" right={0} font="--lead" text-align="center">
								Total Price: £36
							</Override>
							<Override slot="Reorder Group" />
							<Override slot="Price Disclaimer" font="--base" text-align="justify" padding="0px 16px 0px 16px">
								Feel free to add or remove the example add-ons by drag & drop or by clicking the +/- sign on the right.
								<br />
								<Em>
									The add-ons below are examples and may vary based on your specific requirements.
								</Em>
							</Override>
							<Override slot="Price Disclaimer on Mobile" font="--base" text-align="justify" padding="0px 16px 0px 16px">
								Feel free to add or remove the example add-ons or by clicking the +/- sign on the right.
								<br />
								<Em>
									The add-ons below are examples and may vary based on your specific requirements.
								</Em>
							</Override>
							<Override slot="Total Price Wrapper" />
							<Override slot="Delete Button" filter="sepia(50%)" />
							<Override slot="RestorIcoWrapper" display="grid" align-self="flex-end" />
							<Override slot="restor ico label" margin="0 5px 0 0px" text-align="right">
								reset
							</Override>
							<Override slot="AddonsGrouping" display="flex" padding="0px 0px 16px 0px" />
							<Override
								slot="Price sub wrapper"
								display="block"
								align-items="center"
								justify-content="center"
								flex-direction="column"
							/>
							<Override slot="price ext" font="normal 300 15px/1.5 --fontFamily-googlePoppins" text-align="center">
								Applies for the month in which the add-ons are applied.
							</Override>
							<Override slot="ColumnTitle1" color="--primaryBlue" font="--base" opacity="0.8">
								Added Items
							</Override>
							<Override slot="ColumnTitle2" color="--primaryBlue" font="--base" opacity="0.8">
								Removed Items
							</Override>
							<Override
								slot="Column1Wrapper"
								display="grid"
								flex-direction="column"
								align-items="center"
								justify-content="center"
								align-content="center"
								justify-items="center"
								width="100%"
								background="rgba(42, 157, 143, 0.1)"
								border-radius="15px"
								margin="0px 0px 6px 0px"
								padding="0px 6px 0px 6px"
								cursor="default"
							/>
							<Override
								slot="Column2Wrapper"
								display="grid"
								flex-direction="column"
								align-items="center"
								justify-content="center"
								align-content="center"
								justify-items="center"
								width="100%"
								background="rgba(219, 43, 57, 0.1)"
								border-radius="15px"
								margin="6px 0px 16px 0px"
								padding="0px 6px 0px 6px"
								cursor="default"
							/>
							<Override
								slot="Add Icon"
								category="fa"
								icon={FaRegPlusSquare}
								size="32px"
								color="--accent"
								opacity="0.5"
								border-radius="15px"
							/>
							<Box min-width="100px" min-height="100px" padding="16px 0px 0 0px" grid-gap="16px ">
								<List as="ul" font="normal 300 16px/1.5 --fontFamily-googlePoppins" list-style-type="circle">
									<Text>
										<Strong>
											Hosting and Monitoring:
										</Strong>
										{" "}Ensuring your site is always up and running, with up to 100GB of bandwidth per month (that's around 1,500 visitors a day).
									</Text>
									<Text>
										<Strong>
											Service Level Agreement:
										</Strong>
										{" "}Guaranteed quick response if something goes amiss.
									</Text>
									<Text>
										<Strong>
											Built-in Contact Form:
										</Strong>
										{" "}Includes up to 5,000 emails per month.
									</Text>
									<Text>
										<Strong>
											User Change Reviews:
										</Strong>
										{" "}We review any changes you make before they go live to keep everything running smoothly.
									</Text>
									<Text>
										<Strong
											overflow-wrap="normal"
											word-break="normal"
											white-space="normal"
											text-indent="0"
											text-overflow="clip"
											hyphens="manual"
											user-select="auto"
											pointer-events="auto"
										>
											Changes:
										</Strong>
										{" "}Content updates, like changing text or images, are included. Design changes or those requiring development start from £56/task.
									</Text>
								</List>
								<Text font="--lead" text-align="center" margin="32px 0px 0 0px">
									Example Add-ons Cost Calculator
								</Text>
							</Box>
						</Components.AddOnList>
					</Components.SingleAccordion>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="none"
					justify-content="center"
					align-items="center"
					padding="80px 0px 80px 0px"
				>
					<Text margin="0px 0px 0px 0px" font="normal 600 42px/1.2 --fontFamily-googlePoppins" text-align="center">
						<Span font-weight="normal">
							Premium Solutions
						</Span>
					</Text>
				</Box>
				<Box
					grid-template-columns="repeat(2, 1fr)"
					lg-justify-items="center"
					grid-gap="30px"
					md-grid-template-columns="1fr"
					position="relative"
					display="none"
				>
					<Box
						min-width="100px"
						min-height="100px"
						position="absolute"
						width="100%"
						height="100%"
						display="flex"
						align-items="center"
						justify-content="center"
					>
						<Text
							margin="0px 0px 0px 0px"
							padding="16px 16px 16px 16px"
							background="--color-creamHighlight"
							color="--primaryBlue"
							font="--lead"
						>
							Coming Soon!
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						grid-gap="50px"
						filter="blur(5px)"
					>
						<Box min-width="100px" min-height="100px">
							<Box min-width="100px" min-height="100px" display="flex">
								<Box min-width="100px" min-height="100px" width="70%" padding="0px 21px 0px 0px">
									<Text margin="0px 0px 0px 0px" font="--headline3">
										Custom Web Applications
									</Text>
									<Text margin="30px 0px 0px 0px" font="--base" text-align="justify">
										For businesses with unique requirements, we offer bespoke custom web application development. Whether
								you need a client portal, a mini application like a quiz, or complex third-party integrations such as
								Shopify, Strapi, or CRM systems, we have the expertise to deliver tailored solutions. Our team works
								closely with you to understand your needs and create a strategy that ensures seamless user experiences,
								robust performance, and high security.
										<br />
										<br />
										Due to the specialized nature of custom web applications, please contact us for a detailed quote.
									</Text>
								</Box>
								<Box
									min-width="100px"
									min-height="100px"
									width="30%"
									display="grid"
									align-items="start"
									justify-items="center"
								>
									<Image
										src="https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/customwebapps.webp?v=2024-06-27T21:44:21.625Z"
										display="block"
										height="170px"
										border-radius="30px"
										opacity="0.9"
										srcSet="https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/customwebapps.webp?v=2024-06-27T21%3A44%3A21.625Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/customwebapps.webp?v=2024-06-27T21%3A44%3A21.625Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/customwebapps.webp?v=2024-06-27T21%3A44%3A21.625Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/customwebapps.webp?v=2024-06-27T21%3A44%3A21.625Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/customwebapps.webp?v=2024-06-27T21%3A44%3A21.625Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/customwebapps.webp?v=2024-06-27T21%3A44%3A21.625Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/customwebapps.webp?v=2024-06-27T21%3A44%3A21.625Z&quality=85&w=3200 3200w"
										sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
									/>
								</Box>
							</Box>
						</Box>
						<Box min-width="100px" min-height="100px">
							<Box min-width="100px" min-height="100px" display="flex">
								<Box min-width="100px" min-height="100px" width="70%" padding="0px 21px 0px 0px">
									<Text margin="0px 0px 0px 0px" font="--headline3">
										Dynamic Websites with Next.js
									</Text>
									<Text margin="30px 0px 0px 0px" font="--base" text-align="justify">
										In addition to our React-based Gatsby solutions, we also offer dynamic website solutions using Next.js.
								These solutions include server-side generation and a comprehensive CMS setup, providing you with the
								flexibility to manage and update your content seamlessly.
										<br />
										<br />
										Given the complexity and custom requirements of these projects, please contact us for a quote tailored to your specific needs.
									</Text>
								</Box>
								<Box
									min-width="100px"
									min-height="100px"
									width="30%"
									display="grid"
									align-items="start"
									justify-items="center"
								>
									<Image
										src="https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/nextjs.webp?v=2024-06-27T21:59:28.361Z"
										display="block"
										height="170px"
										border-radius="30px"
										opacity="0.9"
										srcSet="https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/nextjs.webp?v=2024-06-27T21%3A59%3A28.361Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/nextjs.webp?v=2024-06-27T21%3A59%3A28.361Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/nextjs.webp?v=2024-06-27T21%3A59%3A28.361Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/nextjs.webp?v=2024-06-27T21%3A59%3A28.361Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/nextjs.webp?v=2024-06-27T21%3A59%3A28.361Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/nextjs.webp?v=2024-06-27T21%3A59%3A28.361Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/nextjs.webp?v=2024-06-27T21%3A59%3A28.361Z&quality=85&w=3200 3200w"
										sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
									/>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="88px 0 88px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			quarkly-title="Advantages/Features-8"
			overflow-y="hidden"
			overflow-x="hidden"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Why Choose Us
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Choosing us means opting for affordability, simplicity, and speed – and getting a website that's as sharp as a Savile Row suit.
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
						border-color="--color-primaryBlue"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 16px/1.5 --fontFamily-googlePoppins"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Affordability
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							We provide well-designed and high-performing websites and web apps at a fraction of the cost of traditional agencies, giving you great value for your money.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
						border-color="--color-primaryBlue"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 16px/1.5 --fontFamily-googlePoppins"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Ease of Communication
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							We use a quick and easy feedback tool that lets you send us changes and updates effortlessly. Just comment on what needs to be adjusted, and we’ll take care of the rest.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
						border-color="--color-primaryBlue"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 16px/1.5 --fontFamily-googlePoppins"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							London-Based
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							We’re proudly UK-based, but we welcome customers from around the world and provide timely support and services.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
						border-color="--color-primaryBlue"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 16px/1.5 --fontFamily-googlePoppins"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Quick Turnaround
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Our team works efficiently to deliver your website or web app in a few days, so you can go live as soon as possible.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
						border-color="--color-primaryBlue"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 16px/1.5 --fontFamily-googlePoppins"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Guaranteed SLA
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Our SiteCare Plan includes a prompt response time on workdays, excluding UK bank holidays and weekends (though emails are monitored during these times). If we miss the agreed response time, that month’s cost will be refunded.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
						border-color="--color-primaryBlue"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 16px/1.5 --fontFamily-googlePoppins"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Proven Track Record
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							We’ve successfully worked on numerous projects, delivering exceptional results. Take a look at a few examples of our work below.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="88px 0 88px 0" quarkly-title="List-2" overflow-y="hidden" overflow-x="hidden">
			<Text margin="0px 0px 40px 0px" font="--headline2" text-align="center" as="h2">
				Our Projects
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 600 20px/1.5 --fontFamily-googlePoppins" text-align="center">
						Business Site for an Accountancy
					</Text>
					<LinkBox
						href="https://bosbalance.com"
						target="_blank"
						transition="all 0.5s ease 0s"
						box-shadow="--xl"
						hover-box-shadow="none"
						margin="0px 0px 30px 0px"
						border-radius="15px"
						hover-transform="scale(0.95)"
					>
						<Image
							src="https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/bosbalance.webp?v=2024-07-01T12:58:19.450Z"
							display="block"
							margin="0px 0px 20px 0px"
							max-width="100%"
							srcSet="https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/bosbalance.webp?v=2024-07-01T12%3A58%3A19.450Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/bosbalance.webp?v=2024-07-01T12%3A58%3A19.450Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/bosbalance.webp?v=2024-07-01T12%3A58%3A19.450Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/bosbalance.webp?v=2024-07-01T12%3A58%3A19.450Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/bosbalance.webp?v=2024-07-01T12%3A58%3A19.450Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/bosbalance.webp?v=2024-07-01T12%3A58%3A19.450Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/bosbalance.webp?v=2024-07-01T12%3A58%3A19.450Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</LinkBox>
					<List
						margin="0px 0px 0px 0px"
						padding="0px 0px 0px 20px"
						list-style-type="circle"
						as="ul"
						font="--base"
						display="grid"
						grid-gap="6px"
					>
						<Text margin="0px 0px 0px 0px">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Plan
							</Strong>
							: Foundation Package, Site Care Plan
						</Text>
						<Text margin="0px 0px 0px 0px">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Add-Ons
							</Strong>
							: Content Writing
						</Text>
						<Text margin="0px 0px 0px 0px">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							/>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Description
							</Strong>
							: We created a professional business website for an accountancy firm, complete with engaging content and
					ongoing site management
						</Text>
					</List>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 600 20px/1.5 --fontFamily-googlePoppins" text-align="center">
						Personality Test Web App
					</Text>
					<LinkBox
						href="https://teszt.sziklaybence.com"
						target="_blank"
						margin="0px 0px 30px 0px"
						transition="all 0.5s ease 0s"
						box-shadow="--xl"
						hover-transform="scale(0.95)"
						border-radius="15px"
						hover-box-shadow="none"
					>
						<Image
							src="https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/ennea.webp?v=2024-07-01T13:02:52.610Z"
							display="block"
							margin="0px 0px 20px 0px"
							max-width="100%"
							srcSet="https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/ennea.webp?v=2024-07-01T13%3A02%3A52.610Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/ennea.webp?v=2024-07-01T13%3A02%3A52.610Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/ennea.webp?v=2024-07-01T13%3A02%3A52.610Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/ennea.webp?v=2024-07-01T13%3A02%3A52.610Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/ennea.webp?v=2024-07-01T13%3A02%3A52.610Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/ennea.webp?v=2024-07-01T13%3A02%3A52.610Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/ennea.webp?v=2024-07-01T13%3A02%3A52.610Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</LinkBox>
					<List
						margin="0px 0px 0px 0px"
						padding="0px 0px 0px 20px"
						list-style-type="circle"
						as="ul"
						font="--base"
						display="grid"
						grid-gap="6px"
					>
						<Text margin="0px 0px 0px 0px">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Plan
							</Strong>
							: Custom Web App, Site Care Plan
						</Text>
						<Text margin="0px 0px 0px 0px">
							<Strong>
								Features
							</Strong>
							: Framer Motion animations, sliders, interactive buttons
						</Text>
						<Text margin="0px 0px 0px 0px">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Add-Ons
							</Strong>
							: PayPal, Airtable, Google Cloud Storage integration.
						</Text>
						<Text margin="0px 0px 0px 0px">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Description
							</Strong>
							: Developed a dynamic personality test web app with enhanced animations and multiple integrations, supported
					by our Site Care Plan
						</Text>
					</List>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 600 20px/1.5 --fontFamily-googlePoppins" text-align="center">
						Cognitive Test Web App
					</Text>
					<LinkBox
						target="_blank"
						href="https://iqresearches.com"
						box-shadow="--xl"
						margin="0px 0px 30px 0px"
						transition="all 0.5s ease 0s"
						hover-box-shadow="none"
						hover-transform="scale(0.95)"
						border-radius="15px"
					>
						<Image
							src="https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/iqres.webp?v=2024-07-01T13:07:46.046Z"
							display="block"
							margin="0px 0px 20px 0px"
							max-width="100%"
							srcSet="https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/iqres.webp?v=2024-07-01T13%3A07%3A46.046Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/iqres.webp?v=2024-07-01T13%3A07%3A46.046Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/iqres.webp?v=2024-07-01T13%3A07%3A46.046Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/iqres.webp?v=2024-07-01T13%3A07%3A46.046Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/iqres.webp?v=2024-07-01T13%3A07%3A46.046Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/iqres.webp?v=2024-07-01T13%3A07%3A46.046Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e4858a867c86001f1cafb1/images/iqres.webp?v=2024-07-01T13%3A07%3A46.046Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</LinkBox>
					<List
						margin="0px 0px 0px 0px"
						padding="0px 0px 0px 20px"
						list-style-type="circle"
						as="ul"
						font="--base"
						display="grid"
						grid-gap="6px"
					>
						<Text margin="0px 0px 0px 0px">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Plan
							</Strong>
							: Custom Web App
						</Text>
						<Text margin="0px 0px 0px 0px">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Add-Ons
							</Strong>
							: PayPal, Airtable.
						</Text>
						<Text margin="0px 0px 0px 0px">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Description:{" "}
							</Strong>
							Created a simple yet effective cognitive test web app, featuring seamless PayPal and Airtable integration,
					along with other custom features.
						</Text>
					</List>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" quarkly-title="FAQ-8" overflow-y="hidden" overflow-x="hidden">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Addressing Your Concerns
			</Text>
			<Text margin="0px 0px 70px 0px" font="--lead" color="#555a5f" sm-margin="0px 0px 50px 0px">
				Your Questions, Clearly Answered – without any faff or fuss!
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--darkL1">
						Can I request modifications if not on a SiteCare Plan?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="#555a5f">
						Yes, of course. Even without a SiteCare Plan, you can request modifications using our pay-per-task model at £86 per task. Think of it as ordering à la carte.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--darkL1">
						How long does it take to get a starter website done with the Foundation Package without add-ons?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="#555a5f">
						Typically, it takes 3-7 days to complete, depending on the approval process. We'll keep you informed if any delays arise – quicker than you can say "Bob's your uncle."
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--darkL1">
						Is there a deposit?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="#555a5f">
						Yes, we require a 35% deposit before starting development. Once it's cleared, we can begin working on your project – a bit like putting a deposit down on a lovely holiday cottage.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--darkL1">
						What if I don't like the design?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="#555a5f">
						We'll keep you updated, sometimes hourly, about our progress. If you don't like something, please let us know as soon as possible so we can make adjustments – we want you to be as happy as Larry with the final result.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--darkL1">
						How can I request changes during and after development?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="#555a5f">
						We use a very easy-to-use website feedback tool. We'll provide you with a link where you can review your pages and place comments (including text, video, or image) on the parts you want us to change. Easy peasy lemon squeezy!
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--darkL1">
						How can I update the content on my website?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="#555a5f">
						You have a few options: you can use our SiteCare Plan and we’ll handle the updates for you, use the visual editor to make changes yourself if you don’t have the SiteCare Plan, or take advantage of our upcoming CMS integration for easy content editing.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section quarkly-title="Contact">
			<Box
				as="section"
				justify-content="center"
				lg-flex-direction="column"
				quarkly-title="Form-8"
				lg-display="grid"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-display="flex"
				display="grid"
				overflow-y="hidden"
				overflow-x="hidden"
				grid-template-columns="repeat(2, 1fr)"
			>
				<Box
					justify-content="flex-end"
					lg-width="100%"
					lg-justify-content="center"
					border-width="0 1px 0 0"
					border-style="solid"
					border-color="--color-grayBg"
					md-border-style="none"
				>
					<Box
						padding="80px 36px 80px 0px"
						lg-padding="60px 0px 60px 0px"
						align-items="flex-start"
						justify-content="flex-start"
						flex-direction="column"
						lg-max-width="90%"
						margin="0px auto 0px auto"
						md-width="100%"
						display="flex"
					>
						<Text
							text-align="left"
							sm-font="--headline2"
							margin="0px 0px 56px 0px"
							font="--headline2"
							color="--dark"
							sm-align-self="center"
						>
							Contacts
						</Text>
						<Text
							font="--base"
							color="--grey"
							text-transform="uppercase"
							letter-spacing="1px"
							margin="0px 0px 17px 0px"
						>
							Registered address
						</Text>
						<Text margin="0px 0px 48px 0px" font="--lead" color="--dark">
							Wavedropper LTD
							<br />
							<br />
							71-75 Shelton Street,
							<br />
							WC2H 9JQ,
							<br />
							London,
							<br />
							UK
						</Text>
						<Text
							color="--grey"
							text-transform="uppercase"
							letter-spacing="1px"
							margin="0px 0px 17px 0px"
							font="--base"
						>
							e-mail
						</Text>
						<Link
							margin="0px 0px 56px 0px"
							href="mailto:blank?hello@company.com"
							text-align="left"
							color="--dark"
							font="--lead"
							text-decoration-line="initial"
						>
							support@wavedropper.com
						</Link>
						<SocialMedia
							margin="0px 0px 0px 0px"
							facebook="https://www.facebook.com/quarklyapp/"
							twitter="https://twitter.com/quarklyapp"
							instagram="https://instagram.com/instagram"
							display="none"
						>
							<Override
								slot="link"
								margin="0 5px 0 5px"
								width="48px"
								hover-color="--light"
								color="--grey"
								background="--color-darkL2"
								hover-background="--color-dark"
								padding="5x 5px 5px 5px"
								height="48px"
								display="flex"
								align-items="center"
								border-radius="50%"
								justify-content="center"
							/>
							<Override slot="icon-facebook" />
							<Override
								slot="icon"
								color="--light"
								padding="7px 7px 7px 7px"
								border-width="0px"
								size="38px"
								border-radius="50px"
							/>
							<Override slot="link-facebook" margin="0px 5px 0px 0px" />
						</SocialMedia>
					</Box>
				</Box>
				<Box
					lg-justify-content="center"
					display="flex"
					lg-width="100%"
					md-order="-1"
					id="contactform"
				>
					<Box
						width="90%"
						max-width="640px"
						display="flex"
						lg-padding="60px 0px 60px 0px"
						padding="80px 0px 80px 36px"
						justify-content="center"
						lg-max-width="90%"
						flex-direction="column"
						align-items="flex-start"
					>
						<Components.EmailForm>
							<Override slot="SubHeadingText" font="--lead" color="--primaryBlue">
								Get in touch with us today to chat about your future website
							</Override>
							<Override slot="HeadingText" color="--primaryBlack" font="--headline2">
								Ready to Get Started?
							</Override>
							<Override slot="text" color="--primaryBlack" display="none" />
							<Override slot="text1" display="none" />
							<Override slot="text2" display="none" />
							<Override
								slot="button"
								background="--color-accent"
								font="--lead"
								border-radius="15px"
								padding="9px 20px 9px 20px"
								focus-box-shadow="none"
							>
								Submit Enquiry
							</Override>
							<Override
								slot="box"
								display="flex"
								justify-items="center"
								align-items="center"
								justify-content="center"
								position="relative"
							/>
							<Override slot="FunPart" margin="16px 0px 32px 0px" font="italic 300 16px/1.5 --fontFamily-googlePoppins" text-align="center" />
						</Components.EmailForm>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer overflow-y="hidden" overflow-x="hidden">
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65e4858a867c86001f1cafaf"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});